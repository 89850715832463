import React from 'react'

import BackgroundSection from '../components/background-section'

const FeatureHeadingSection = ({ iconSrc, children }) => (
  <BackgroundSection className="feature-heading centered">
    <img src={iconSrc} alt="" />
    <h1>{children}</h1>
  </BackgroundSection>
)

export default FeatureHeadingSection
